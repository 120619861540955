<template>
  <van-popup v-model="showPicker" round position="bottom">
    <van-area
      :area-list="areaList"
      :value="code"
      :columns-num="keys.length || columnsNum"
      :columns-placeholder="['请选择', '请选择', '请选择']"
      :swipe-duration="300"
      @confirm="onConfirm"
      @change="onChange"
      @cancel="showPicker = false"
      title="标题"
    />
  </van-popup>
</template>
<script>
import { areaList } from "@vant/area-data";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    keys: {
      type: Array,
      default: () => []
    },
    code: {
      type: String,
      default: ""
    },
    columnsNum: {
      type: Number,
      default: 3
    }
  },
  data() {
    return { areaList };
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        // if (v) this.code = "";
        this.$emit("input", v);
      }
    }
  },
  methods: {
    onChange(v) {
      console.log(v);
    },
    onConfirm(v) {
      const codes = v.map((e) => e.code);
      const names = v.map((e) => e.name);
      if (!codes[codes.length - 1]) {
        this.$errMsg("至少选择到市区");
        return;
      }
      this.showPicker = false;
      this.$emit("confirm", codes, names);
    }
  }
};
</script>
